import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Input, Button, Space, message, Col, Row, Typography } from "antd";
import Cookies from "js-cookie";
import {
  getResponseTemplate,
  submitResponseTemplate,
} from "../../services/userService";
import { createNotification } from "../../services/conversationService";
import "../../reducers/dashboardReducer";

const { TextArea } = Input;
const { Title } = Typography;

const ResponseTemplate = ({ refreshNotifications }) => {
  const [form] = Form.useForm();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const { screenSize } = useSelector(({ dashboardReducer }) => dashboardReducer);

  useEffect(() => {
    const fetchTemplateData = async () => {
      try {
        const organizationId = Cookies.get("organizationId");
        const responseData = await getResponseTemplate(organizationId);
        form.setFieldsValue({
          aboutUs: responseData.about,
          specialInstructions: responseData.specialInstructions,
          shippingPolicy: responseData.shippingPolicy,
          returnPolicy: responseData.returnPolicy,
          paymentType: responseData.paymentType,
        });
        setIsButtonDisabled(!responseData.about);
      } catch (error) {
        message.error("Failed to load response template data");
      }
    };

    fetchTemplateData();
  }, [form]);

  const onFinish = async (values) => {
    const requestData = {
      about: values.aboutUs,
      specialInstructions: values.specialInstructions,
      shippingPolicy: values.shippingPolicy,
      returnPolicy: values.returnPolicy,
      paymentType: values.paymentType,
    };

    try {
      await submitResponseTemplate(requestData);
      message.success("Response Template Updated Successfully");

      // Create notification
      try {
        const notificationMessage = "Response Template has been updated.";
        await createNotification(notificationMessage);
        refreshNotifications();
      } catch (notificationError) {
        console.error("Notification Error:", notificationError);
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("Failed to update response template");
    }
  };

  const handleFormChange = (changedValues, allValues) => {
    setIsButtonDisabled(!allValues.aboutUs);
  };

  return (
    <Row justify="center">
      <Col span={24}>
        <Title level={screenSize < 576 ? 3 : 2} style={{ textAlign: "center", marginBottom: "20px" }}>
          Response Template
        </Title>
      </Col>

      <Col md={15} xs={24} style={{ display: "flex", flexDirection: "column", height: "80vh" }}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onValuesChange={handleFormChange}
          style={{ flex: 1, overflowY: "auto" }} // Scroll only in form
          initialValues={{
            aboutUs: "",
            specialInstructions: "",
            shippingPolicy: "",
            returnPolicy: "",
            paymentType: "",
          }}
        >
          <Form.Item
            label="About Us"
            name="aboutUs"
            rules={[{ required: true, message: "Please input the About Us section!" }]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item label="Special Instructions" name="specialInstructions">
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item label="Shipping Policy" name="shippingPolicy">
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item label="Return Policy" name="returnPolicy">
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item label="Payment Type" name="paymentType">
            <Input />
          </Form.Item>
        </Form>

        <div
          style={{
            position: "relative",
            padding: "10px 0",
            backgroundColor: "#fff",
            textAlign: "center",
            // borderTop: "1px solid #ddd",
          }}
        >
          <Space size="large">
            <Button type="primary" htmlType="submit" onClick={() => form.submit()} disabled={isButtonDisabled} className="btn-size">
              Update
            </Button>
          </Space>
        </div>
      </Col>
    </Row>
  );
};

export default ResponseTemplate;
